<template>
  <div>
    <div v-if="painel.ativo">
      <audio src="@/assets/audios/alerta1.mp3"></audio>
      <div class="row" v-show="chamando">
        <div
          class="d-flex justify-content-center align-items-center p-1"
          :style="
            'width: 100%; height: 100%; background:' +
            painel.cor_card +
            '; flex-direction: column;'
          "
        >
          <span
            :style="
              'color:' +
              painel.cor_primaria +
              ';   font-size: 16vh;  line-height: 18vh;'
            "
            >Senha</span
          ><br />
          <span
            :style="
              'color:' +
              painel.cor_secundaria +
              '; font-size: 28vh; line-height: 20vh;'
            "
            >{{ senha_atual.prefixo
            }}<span :style="'color:' + painel.cor_primaria + ';'">{{
              senha_atual.senha
            }}</span></span
          ><br />
          <span
            :style="
              'color:' +
              painel.cor_primaria +
              ';   font-size: 15vh;  line-height: 17vh;'
            "
            >Local</span
          ><br />
          <span
            :style="
              'color:' +
              painel.cor_primaria +
              ';   font-size: 13vh;  line-height: 12vh;'
            "
            >{{ senha_atual.local }}</span
          ><br />
          <span
            :style="
              'color:' +
              painel.cor_primaria +
              ';   font-size: 5vh;  line-height: 6vh;'
            "
            v-if="senha_atual.pessoa && painel.mostra_pessoa"
            >{{ senha_atual.pessoa }}</span
          ><br />
        </div>
      </div>
      <div class="row" v-show="!chamando">
        <div
          class="col-md-12 px-3 py-2"
          :style="
            'font-family: helvetica; height: 100vh; background: radial-gradient(' +
            painel.background +
            ', #000000); display: flex;flex-wrap: wrap;'
          "
        >
          <div
            :style="
              'width: 68.5%; height: 84%; background:' +
              painel.cor_card +
              '; border-radius:' +
              painel.arredondamento +
              'px; margin-right: 1.5%;'
            "
            v-show="!painel.video"
          >
            <div
              class="d-flex justify-content-center align-items-center"
              style="flex-direction: column; height: 100%"
            >
              <span
                :style="
                  'color:' +
                  painel.cor_primaria +
                  ';   font-size: 6vh;  line-height: 6vh;'
                "
                >Senha</span
              ><br />
              <span
                :style="
                  'color:' +
                  painel.cor_secundaria +
                  '; font-size: 10vh; line-height: 7vh;'
                "
              >
                {{ senha_atual.prefixo
                }}<span :style="'color:' + painel.cor_primaria + ';'">{{
                  senha_atual.senha
                }}</span> </span
              ><br />
              <span
                :style="
                  'color:' +
                  painel.cor_primaria +
                  ';   font-size: 6vh;  line-height: 6vh;'
                "
                >Local</span
              ><br />
              <span
                :style="
                  'color:' +
                  painel.cor_primaria +
                  ';   font-size: 9vh;  line-height: 7vh;'
                "
                >{{ senha_atual.local }}</span
              ><br />
              <span
                :style="
                  'color:' +
                  painel.cor_primaria +
                  ';   font-size: 6vh;  line-height: 7vh;'
                "
                class="text-center"
                v-show="painel.mostra_pessoa"
              >
                {{ senha_atual.pessoa }} </span
              ><br />
            </div>
          </div>
          <div
            :style="
              'width: 68.5%; height: 84%; background: #000000;' +
              'border-radius:' +
              painel.arredondamento +
              'px; margin-right: 1.5%;'
            "
            v-show="painel.video"
          >
            <div
              class="d-flex justify-content-center align-items-center"
              style="flex-direction: column; height: 100%"
            >
              <video
                :src="painel.video_unidade ? painel.video_unidade.video_link : ''"
                width="100%"
                autoplay
                muted
                loop
              ></video>
            </div>
          </div>
          <div
            class="d-flex text-center p-0"
            :style="
              'width: 30%; height: 84%; font-size: 3.7vh; color: ' +
              painel.cor_primaria +
              '; background:' +
              painel.cor_card +
              '; border-radius:' +
              painel.arredondamento +
              'px; flex-direction: column;'
            "
          >
            <div
              class="p-0 d-flex align-items-center justify-content-center"
              style="min-height: 7%"
            >
              Últimas Chamadas
            </div>
            <div
              class="d-flex flex-column align-items-center justify-content-center"
              v-for="(senha, index) in senhas"
              :key="senha + index + ''"
              style="height: 15% !important; border-top: 1px solid #000"
            >
              <div
                :style="
                  'color:' +
                  painel.cor_secundaria +
                  ';' +
                  (senha.pessoa && painel.mostra_pessoa
                    ? 'font-size: 3vh; line-height: 3vh;'
                    : 'font-size: 3.5vh;')
                "
              >
                {{ senha.prefixo
                }}<span :style="'color:' + painel.cor_primaria">{{
                  senha.senha
                }}</span>
              </div>
              <div
                :style="
                  senha.pessoa && painel.mostra_pessoa
                    ? 'font-size: 2.5vh; line-height: 3vh;'
                    : 'font-size: 3.5vh;'
                "
              >
                {{ senha.local }}
              </div>
              <div
                :style="
                  senha.pessoa ? 'font-size: 2vh; line-height: 1.5vh;' : ''
                "
                v-show="painel.mostra_pessoa"
              >
                {{ senha.pessoa }}
              </div>
            </div>
          </div>
          <div
            :class="
              painel.relogio
                ? 'd-flex align-items-center justify-content-center'
                : 'd-none'
            "
            :style="
              'border-radius:' +
              painel.arredondamento +
              'px; width: 10%; height: 14%; background: ' +
              painel.cor_card +
              '; margin-top: 1.5%; flex-direction: column;'
            "
          >
            <span
              :style="
                'font-weight: 700; color:' +
                painel.cor_primaria +
                '; font-size: 2.5vh;'
              "
              >{{ data }}</span
            >
            <span
              :style="
                'font-weight: 700; color:' +
                painel.cor_primaria +
                '; font-size: 2.5vh;'
              "
              >{{ hora }}</span
            >
          </div>
          <div
            :class="
              painel.previsao
                ? 'd-flex align-items-center justify-content-center'
                : 'd-none'
            "
            :style="
              'border-radius:' +
              painel.arredondamento +
              'px; width: 19%; height: 14%; background: ' +
              painel.cor_card +
              '; margin-top: 1.5%; margin-left: ' +
              (painel.relogio ? '1' : '0') +
              '%;'
            "
          >
            <div class="img-lateral" v-show="previsao.entidade">
              <img
                :src="previsao.icone"
                id="icone"
                alt="icone"
                height="80%"
              /><br />
            </div>
            <div class="texto" v-show="previsao.entidade">
              <div id="tempo">
                <b>{{ previsao.entidade }}, {{ previsao.date }}</b>
              </div>
              <img
                :src="previsao.temp_min_tende_icone"
                alt="img_temp_min"
                id="img_temp_min"
                height="20%"
                class="ml-1"
              />&nbsp; <span id="min">{{ previsao.temp_min }}</span
              ><span class="arial">º</span>
              <img
                :src="previsao.temp_max_tende_icone"
                alt="img_temp_max"
                id="img_temp_max"
                height="20%"
                class="ml-1"
              />&nbsp; <span id="max">{{ previsao.temp_max }}</span
              ><span class="arial">º</span>
              <div class="resumo"></div>
            </div>
          </div>
          <div
            class="d-flex align-items-center justify-content-center"
            :style="
              'overflow: hidden; border-radius:' +
              painel.arredondamento +
              'px; width: ' +
              (100 - ((painel.relogio ? 11 : 0) + (painel.previsao ? 20 : 0) + (painel.logo ? 31.5 : 0))) +
              '%; height: 14%; background: ' + painel.cor_card +
              '; margin-top: 1.5%; margin-left: ' +
              (painel.relogio + painel.previsao ? '1' : '0') +
              '%;'
            "
          >
            <span
              :style="'color:' + painel.cor_primaria + '; font-size: 6vh;'"
              id="mensagens"
              >{{ painel.mensagem }}</span
            >
          </div>
          <div
          :class="painel.logo
                ? 'd-flex align-items-center justify-content-center'
                : 'd-none'
            "
            :style="
              'border-radius:' + painel.arredondamento +
              'px; width: 30%; height: 14%; background: ' +
              painel.cor_card +
              '; margin-top: 1.5%; margin-left: 1.5%;'
            "
            >
            <img :src="painel.logo_f" style="max-height: 90%; max-width: 98%;">
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="d-flex justify-content-center align-items-center p-1"
      :style="'width: 100vw; height: 100vh; background:' + painel.background + '; flex-direction: column;'"
    >
      <h1 :style="'color:' + painel.cor_card">PAINEL INATIVO</h1>
    </div>
  </div>
</template>

<script>
import { BCardText, BLink } from "bootstrap-vue";

import { BCard, BCardBody, BAvatar } from "bootstrap-vue";

export default {
  data() {
    return {
      previsao: [],
      detecta_chamada: "",
      chamando: 0,
      painel: {
        ativo: 1,
      },
      data: "",
      hora: "",
      senha_atual: {
        prefixo: "-",
        senha: "----",
        local: "-",
      },
      senhas: {
        0: {
          id: "",
          prefixo: "-",
          senha: "----",
          local: "-",
        },
        1: {
          id: "",
          prefixo: "-",
          senha: "----",
          local: "-",
        },
        2: {
          id: "",
          prefixo: "-",
          senha: "----",
          local: "-",
        },
        3: {
          id: "",
          prefixo: "-",
          senha: "----",
          local: "-",
        },
        4: {
          id: "",
          prefixo: "-",
          senha: "----",
          local: "-",
        },
        5: {
          id: "",
          prefixo: "-",
          senha: "----",
          local: "-",
        },
      },
    };
  },
  components: {
    BCard,
    BCardText,
    BLink,
    BCard,
    BCardBody,
    BAvatar,
  },
  methods: {
    getPainel: function (id) {
      const self = this;
      const api =
        self.$store.state.api + `paineis/${id}?with[]=video_unidade`;

      axios
        .get(api)
        .then((response) => {
          self.painel = response.data.data[0];

          var token = localStorage.getItem("token");
          if (!token) {
            self.login();
          }

          if (self.painel.previsao) {
            self.getPrevisao();
          }
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getPrevisao: function () {
      const self = this;
      const api =
        "https://apiprevmet3.inmet.gov.br/previsao/" + self.painel.ibge;

      axios
        .get(api)
        .then((response) => {
          console.clear();

          let data = new Date();
          let diaAtual = data.toLocaleDateString().split("/")[0];
          let dia = data.toLocaleDateString().split("/")[0];
          let mes = data.toLocaleDateString().split("/")[1];
          let ano = data.toLocaleDateString().split("/")[2];
          let hora = data.getHours();
          let dataTratada = [];
          let i = 0;

          var previsao = setInterval(function () {
            if (parseInt(dia) + i - parseInt(diaAtual) < 2) {
              switch ("data") {
                case hora > 12:
                  dataTratada = self.trataData(mes, parseInt(dia) + i);
                  self.previsao =
                    response.data[self.painel.ibge][
                      dataTratada[0] + "/" + dataTratada[1] + "/" + ano
                    ]["manha"];
                  self.previsao.date = dataTratada[0] + "/" + dataTratada[1];
                  break;
                case hora < 17:
                  dataTratada = self.trataData(mes, parseInt(dia) + i);
                  self.previsao =
                    response.data[self.painel.ibge][
                      dataTratada[0] + "/" + dataTratada[1] + "/" + ano
                    ]["noite"];
                  self.previsao.date = dataTratada[0] + "/" + dataTratada[1];
                  break;
                default:
                  dataTratada = self.trataData(mes, parseInt(dia) + i);
                  self.previsao =
                    response.data[self.painel.ibge][
                      dataTratada[0] + "/" + dataTratada[1] + "/" + ano
                    ]["tarde"];
                  self.previsao.date = dataTratada[0] + "/" + dataTratada[1];
                  break;
              }
            } else {
              dataTratada = self.trataData(mes, parseInt(dia) + i);
              self.previsao =
                response.data[self.painel.ibge][
                  dataTratada[0] + "/" + dataTratada[1] + "/" + ano
                ];
              self.previsao.date = dataTratada[0] + "/" + dataTratada[1];
            }

            if (i < 4) {
              i++;
            } else {
              i = 0;
            }
          }, 5000);
        })
        .catch((error) => {
          console.log(error);
          self.$message(
            null,
            "Aguarde 5 minutos e recarregue a página por favor",
            "error"
          );
        });
    },
    trataData: function (mes, dia) {
      let mes31 = ["1", "3", "5", "7", "8", "10", "12"];
      let mes30 = ["4", "6", "9", "11"];

      switch (mes) {
        case mes30.includes(mes) && dia > 30:
          dia = dia - 30;
          dia = "0" + dia;
          mes = parseInt(mes) + 1;
          break;
        case mes31.includes(mes) && dia > 31:
          dia = dia - 31;
          dia = "0" + dia;
          mes = parseInt(mes) + 1;
          break;
        case mes == 2 && dia > 28:
          dia = dia - 28;
          dia = "0" + dia;
          mes = parseInt(mes) + 1;
          break;
        default:
          if (parseInt(dia) < 10) {
            dia = "0" + dia;
          }
          break;
      }

      return [dia, mes];
    },
    getSenhas: function (id) {
      const self = this;
      const api = self.$store.state.api + "senhas/" + id;

      axios
        .get(api)
        .then((response) => {
          response = response.data;

          for (let i = 0; i < 6; i++) {
            if (response[i].atendimento) {
              if (i) {
                self.senhas[i] = {
                  id:      response[i].atendimento.id        ? response[i].atendimento.id          : "",
                  prefixo: response[i].atendimento.prefixo   ? response[i].atendimento.prefixo     : "",
                  senha:   response[i].atendimento.senha     ? response[i].atendimento.senha       : "",
                  local:   response[i].local.nome            ? response[i].local.nome              : "",
                  pessoa:  response[i].atendimento.pessoa_id ? response[i].atendimento.pessoa.nome : "",
                };
              } else {
                self.senhas[i] = {
                  id:      response[i].atendimento.id        ? response[i].atendimento.id          : "",
                  prefixo: response[i].atendimento.prefixo   ? response[i].atendimento.prefixo     : "",
                  senha:   response[i].atendimento.senha     ? response[i].atendimento.senha       : "",
                  local:   response[i].local.nome            ? response[i].local.nome              : "",
                  pessoa:  response[i].atendimento.pessoa_id ? response[i].atendimento.pessoa.nome : "",
                };

                self.senha_atual = {
                  id:      response[i].atendimento.id        ? response[i].atendimento.id          : "",
                  prefixo: response[i].atendimento.prefixo   ? response[i].atendimento.prefixo     : "",
                  senha:   response[i].atendimento.senha     ? response[i].atendimento.senha       : "",
                  local:   response[i].local.nome            ? response[i].local.nome              : "",
                  pessoa:  response[i].atendimento.pessoa_id ? response[i].atendimento.pessoa.nome : "",
                };
              }
            } else {
              self.senhas[i] = {
                id: "",
                prefixo: "-",
                senha: "----",
                local: "-",
                pessoa: "",
              };
            }
          }

          if(self.painel.video){
            if (response[0].alertado == 0) {
              self.chamarSenha(response[0].id);
            }
          }else{
            if (response[0].alertado != 0) {
              self.chamarSenha(response[0].id);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getWebSocket(id) {
      const self = this;

      setTimeout(function () {
        Echo.private("painel." + id).listen(".PainelEvent", (data) => {
          self.getSenhas(self.$route.params.id);
        });
      }, 3000);
      setTimeout(function () {
        Echo.private("painel_mensagem." + 1).listen(
          ".PainelMensagemEvent",
          (data) => {
            self.speakText(data.texto);
          }
        );
      }, 3000);
    },
    getHorario: function () {
      const self = this;

      setInterval(() => {
        const date = new Date();
        let ano = date.getFullYear();
        let mes = date.getMonth() + 1;
        let dia = date.getUTCDate();
        let horas = date.getHours();
        let minutos = date.getMinutes();
        let segundos = date.getSeconds();

        self.hora =
          horas.toString().padStart(2, "0") +
          ":" +
          minutos.toString().padStart(2, "0") +
          ":" +
          segundos.toString().padStart(2, "0");
        self.data =
          dia.toString().padStart(2, "0") +
          "/" +
          mes.toString().padStart(2, "0") +
          "/" +
          ano;
      }, 1000);
    },
    chamarSenha: function (id) {
      const self = this;
      self.chamando = 1;

      if (self.painel.alerta === "alerta_padrao.mp4") {
        $("audio").trigger("play");
      } else {
        $("audio").trigger("play");

        self.speakText(
          "senha, " +
            self.senhas[0].prefixo.split("") +
            ", " +
            self.senhas[0].senha.split("") +
            ", " +
            self.senhas[0].pessoa +
            ", " +
            self.senhas[0].local
        );
      }

      const api = this.$store.state.api + "set-alertado/";

      axios
        .post(api, { id: id })
        .then((response) => {})
        .catch((error) => {
          self.$message("Erro", "Erro ao setar o alertado", "error");
        });

      setTimeout(function () {
        self.chamando = 0;
      }, 3500);
    },
    async speakText(text) {
      const synth = window.speechSynthesis;
      const utterance = new SpeechSynthesisUtterance();
      utterance.text = text;
      utterance.lang = "pt-BR";
      utterance.volume = 1; // volume máximo
      utterance.rate = 1; // velocidade normal
      utterance.pitch = 1; // tom normal
      synth.speak(utterance);
      utterance.text = "";
    },
    login() {
      const self = this;
      const api = this.$store.state.api + "login";
      self.$loading(true);

      axios
        .post(api, {
          email: "sistemas@it4d.com.br",
          password: "SystemPass",
        })
        .then((response) => {
          self.$store.commit("auth", response.data.access_token);
          window.location.reload();
          self.$loading(false);
        })
        .catch((error) => {
          self.$loading(false);
          self.$message("Erro", error.response.data, "error");
        });
    },
  },
  mounted() {
    const self = this;
    let id = self.$route.params.id;

    self.getHorario();

    if (id) {
      self.getPainel(id);
      self.getSenhas(id);
      self.getWebSocket(id);
    }
  },
  created() {},
};
</script>

<style>
html,
body {
  font-family: helvetica;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation: fadeIn 1.3s ease-in-out;
  -moz-animation: fadeIn 1.3s ease-in-out;
  -o-animation: fadeIn 1.3s ease-in-out;
  animation: fadeIn 1.3s ease-in-out;
}

body {
  overflow-x: hidden;
}

div > #mensagens {
  display: relative;
  min-width: 230%;
  grid-template-columns: repeat(1, 0.25fr);
  justify-content: space-between;
  transform: translateX(100z);
  animation: anima 20s linear infinite;
}

#mensagens:before,
#mensagens:after {
  content: "";
  display: relative;
}

#mensagens:after {
  transform: translate3d(100%, 0, 0);
}

@keyframes anima {
  from {
    transform: translateX(110%);
  }

  to {
    transform: translateX(-160%);
  }
}
</style>